.variant-options {
  width: 100%;
  display: inline-block;
}

.selected-option {
  text-align: center !important;
  font-weight: bolder;
  display: inline-block;
  color: #747574;
}

.variants {
  color: #333333 !important;
}

.variants:hover {
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
}

.is-swatch {
  border-radius: 100% !important;
  width: 35px;
  height: 35px;
  border: 2px solid #ffffff;
}

.is-swatch:hover,
.is-swatch:active,
.selected-variant-is-swatch {
  box-shadow: 0 0 0 3px #c7c7c7;
}

.is-button {
  width: 80px;
  border: none !important;
  height: 50px;
  border-radius: var(--bradius);
  color: #333333 !important;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: var(--bradius);
  -moz-border-radius: var(--bradius);
  -ms-border-radius: var(--bradius);
  -o-border-radius: var(--bradius);
}

.is-button:hover,
.is-button:active,
.is-button:focus,
.selected-variant-is-button {
  box-shadow: 0 0 0 0px #ffffff !important;
  border: none !important;
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
}

.circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  cursor: pointer;
  position: absolute;
  box-shadow: 0 0 0 3px #c7c7c7;
}

.swatch-diagonal-line {
  width: 6px;
  height: 28.5px;
  background-color: var(--mf-white);
  transform: rotate(45deg) translate(9px, -8px);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 1px;
}

.swatch-diagonal-line::before {
  content: "";
  display: block;
  width: 3px;
  height: 32px;
  background-color: #c7c7c7;
  transform: translate(1.5px, -2px);
}

.button-diagonal-line {
  width: 2px;
  height: 94px;
  background-color: var(--mf-black);
  transform: rotate(59deg) translate(-30px, -60px);
  position: absolute;
}

.is-button.disabled {
  border: solid 1px var(--mf-black) !important;
}
