.pager-container {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 0.25rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.pager-container nav ul {
  margin: 0;
}

.pager-container nav ul li.page-item button {
  border-radius: 999px !important;
  color: currentColor !important;
  font-size: 0.875em;
  border: none;
  background-color: transparent;
  width: 38px;
  height: 38px;
  white-space: nowrap;
}

.pager-container nav ul li.page-item.disabled button {
  background-color: transparent;
  filter: opacity(0.5);
}

.pager-container nav ul li.page-item.active button {
  background-color: var(--mf-secondary-color);
  color: #fff !important;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.pager-container nav ul li button:hover,
.pager-container nav ul li button:focus {
  background-color: #e2e0e0;
  box-shadow: none;
}

.pager-container span.pager-range {
  font-size: 0.875em;
}
