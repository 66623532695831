/*------------------------------------------------------------
  5.1.2 footer
-----------------------------------------------------------*/
// Footer one style
.iron-footer-v1 {
  .iron-footer-top {
    background-color: #1d1d1d;
    padding-top: 1.75rem;
    bottom: 0;
  }
  .footer-hr {
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  * {
    color: #90969a;
  }
  .footer-widget-title {
    h6 {
      color: #fff;
      font-weight: $font-weight-base;
      text-transform: uppercase;
    }
  }
  .iron-footer-menu-list {
    padding: 0;
    .list-item {
      padding: 0 0 0.3125rem 0;
      margin-bottom: 0.625rem;
      span {
        color: #fff;
        text-transform: capitalize;
        font-size: 0.875rem;
      }
    }
  }
  .iron-footer-bottom {
    padding: 2.1875rem 0;
    background-color: $black;
  }
}

.iron-footer-v1 .iron-footer-menu-list ul li {
  font-weight: 100;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  list-style: none;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-decoration: none;
}

.iron-footer-v1 .footer-img {
  width: 60%;
  padding-bottom: 30px;
}

.footer-right h6 {
  opacity: 0;
}

.footer-right p {
  text-align: right;
}

@media only screen and (max-width: 959px) {
  .footer-right p {
    text-align: left;
  }
}
