.manageadressbtn {
  border: 2px solid var(--mf-primary-color) !important;
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
  width: 200;
  float: right;
  margin-top: 0;
  padding: 0.5em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  line-height: 2 !important;
}

.manageadressbtn span {
  color: var(--mf-primary-color) !important;
}

.manageadressbtn:hover,
.manageadressbtn:focus,
.manageadressbtn:active {
  border: 2px solid var(--mf-primary-color) !important;
  background-color: var(--mf-transparent) !important;
  color: var(--mf-primary-color) !important;
}

.btn-pressed {
  background-color: var(--mf-primary-color) !important;
}
.btn-pressed span {
  color: var(--mf-white) !important;
}
