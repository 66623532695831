/*------------------------------------------------------------
    4.4 color
-------------------------------------------------------------*/

.primary-color {
  color: $primary;
}
.secondary-color {
  color: $secondary;
}
.success-color {
  color: $success;
}
.danger-color {
  color: $danger;
}
.warning-color {
  color: $warning;
}
.info-color {
  color: $info;
}
.dark-color {
  color: $dark;
}
.active-color {
  color: $active;
}
.base-color {
  color: $base;
}
