/* styles.css */
.icon-right-margin {
  margin-right: 8px; /* Adjust the value as needed */
}

.dropdown-item-full-width {
  display: block; /* Ensure DropdownItem is full width */
  text-align: center; /* Center text */
}

.dropdown-button-full-width {
  width: 100%; /* Make button full width */
  text-align: center; /* Center text inside button */
  justify-content: center; /* Center for Flexbox layouts, if applicable */
  font-size: 1rem !important;
}

/* icons.css */
.icon-size-20 {
  width: 20px;
  height: 20px;
}
.btn-smaller-height {
  height: 41px;
}

.dropdown-background {
  z-index: -1;
  opacity: 0.3;
}

.dropdown-item-custom {
  display: flex;
  align-items: center;
}

.terms-and-conditions-dropdown {
  display: flex;
  padding: 0;
  margin-right: -58px; /* Adjust this value as needed */
  padding-left: 10px;
}

.terms-and-conditions-link-container {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: transparent;
}

.terms-and-conditions-link {
  text-decoration: none !important;
  font-size: 0.8rem;
}

.divider {
  align-self: center;
  font-size: 0.8rem;
  margin: 0 10px;
  margin-left: 60px;
}

.contact-us-dropdown {
  display: flex;
  padding: 0;
}

.contact-us-link-container {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-left: -10px;
  background-color: transparent;
}

.contact-us-link {
  text-decoration: none !important;
  font-size: 0.8rem;
}

.dropdown-item-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contact-us-center-container {
  text-align: center;
}
