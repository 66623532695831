@import 'headers/headerwidgets';
@import 'headers/headerOne';
@import 'headers/fixedHeader.scss';
@import 'sidebar';
@import 'footers/footerOne';
@import 'admin/pages';
@import 'admin/header';

// Override default Bootstrap container width
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1540px !important;
}
