@import 'layouts/layouts';
@import 'widgets/widgets';
@import 'rc-tabs/default';
@import 'rsuite/default';
@import 'react-credit-cards/default';

// react-phone-number-input - make it look like bootstrap
.PhoneInputInput {
  border: 1px solid #ced4da;
  border-radius: var(--bradius);
  padding: 6px 12px;
  color: #495057;
}

.default-border-radius {
  border-radius: var(--bradius);
}

.address-label {
  line-height: 1.1em;
  font-size: 0.85em;
}

// Center align items in dxPopup
.dx-popup-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-confirmation-container {
  text-align: center;
  padding: 40px;
}

.custom-input-number {
  input {
    text-align: center;
  }

  .rs-input-number-btn-group-vertical {
    display: none;
  }
}

:root {
  --mf-danger: #a20000;
  --mf-warning: #f3991b;
  --mf-warning-dark: #c17c1b;
  --mf-lightgrey: #e4e4e4;
  --mf-root-background: #f5f5f5;
  --mf-transparent: #ffffff00;
  --mf-green-co-old: #44a705;
  --mf-green-co: #388409;
  --bradius: 4px;
}
// body background color overrite, should always match background color due to cart page
body {
  background-color: var(--mf-root-background) !important;
}

.sweetalert-overflow-hidden {
  overflow: hidden;
}

.header-color {
  background-color: var(--white) !important ;
}

#root {
  background-color: var(--mf-root-background);
}

.bradius {
  border-radius: var(--bradius);
}

#app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1 0 auto;
}

#application-footer {
  width: 100%;
  height: auto;
  background-color: var(--mf-transparent) !important;
  z-index: 1;
  flex-shrink: 0;
}

.order-summary-box td {
  text-align: right;
}

.cart-item-loading {
  opacity: 50%;
}

.order-summary-box td {
  text-align: right;
}

.cart-item-loading {
  opacity: 50%;
}

.noselect::selection {
  color: none;
  background: none;
}

tbody.cartItemExtraInfo > tr.cartItemExtraInfo > td {
  border: none;
  font-size: 0.8em;
  line-height: 0.8em;
}

.center {
  display: flex;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;
  width: 100px;
  height: 100px;
  position: fixed;
  left: 50%;
  top: 40%;
  background-color: #36d7b7;
  transform: translate(50%, -50%);
  z-index: 10000000;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.smart-pager.smart-element {
  background-color: transparent;
  background: transparent;
  border-radius: 7px;
}

.iron-app-logo img {
  max-height: 75px;
  float: left;
}

.price-content {
  width: 100%;
  display: inline-block;
}

.price-content p {
  margin-bottom: 0;
  font-size: 1rem;
}

.signout-button {
  color: #fff !important;
  font-weight: 400 !important;
}

.signout-button:hover {
  background-color: #850017 !important;
}

.address_error {
  color: red;
}

.red-text {
  color: red;
}

.address_checkbox {
  display: flex;
  align-items: center;
}

.address_checkbox p {
  margin-bottom: 0;
}

.checkout-section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.checkout-section .expansion-panel {
  min-height: 60px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

.checkout-information {
  margin-left: 10px;
  font-size: 14px;
  width: calc(100% - 50px);
}

.delivery-section {
  width: 100%;
  min-height: 60px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background: #fff;
  padding: 10px 24px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.delivery-title {
  margin-left: 8px;
  color: #000;
  width: 100%;
}

.basket-section {
  width: 100%;
  min-height: 60px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background: #fff;
  padding-bottom: 20px;
}

.basket-title {
  padding: 12px 24px;
  color: #000;
}

.basket-section .rct-card-wrap {
  margin: 0 30px 10px;
  box-shadow: none;
}

.basket-section .rct-card-wrap.items {
  margin: 0 30px 10px;
  box-shadow: none;
  padding: 0;
}

.item-container {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  margin-bottom: 10px;
}

.item-container:first-child {
  border-bottom: 1px solid #ccc;
  padding: 0 0 20px 0;
}

.item-container:last-child {
  border-bottom: 0;
  padding: 10px 0 0 0;
}

.item-content {
  padding-top: 15px;
}

.input-form {
  width: 100%;
}

.order-summary {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: var(--bradius);
  padding: 15px;
  margin: 0 0 10px 10px;
}

.order-summary p {
  margin-bottom: 5px;
}

.order-summary p:last-child {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.discount-summary {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: var(--bradius);
  padding: 15px;
  margin: 0 0 10px 10px;
}

.discount-summary p {
  margin-bottom: 5px;
}

.payment-total {
  color: #c52d07;
  margin-top: 5px;
}

// .checkout-btn button {
//   padding: 5px 12px;
//   border: 1px solid;
//   border-radius: 2px;
//   background: #efc016;
//   font-size: 0.875rem !important;
//   width: 100%;
//   cursor: pointer;
//   color: #fff;
// }
.iron-footer-v1 {
  position: relative;
  width: 100%;
  bottom: 0;
}
.iron-footer-v1 .iron-footer-top {
  margin-top: 3em;
}

.basket-total {
  padding: 10px 30px 0 30px;
}

.rAlign {
  float: right;
}

.cart-row {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.iron-header-v1 .top-links-container .links li.navbar-coop {
  color: #08ad25;
}

// Account Credits & CO-OP Menu Bar Boxes BEGINS
.credits-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  background: transparent;
  padding: 5px 15px;
  height: 51px;
  border-radius: var(--bradius);
  border: 2px solid var(--mf-primary-color);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 130px;

  h6,
  h5 {
    margin: auto;
    color: var(--mf-primary-color);
    width: auto;
  }

  h5 {
    font-size: 1.2rem;
  }

  p {
    margin: 0;
    color: var(--mf-primary-color);
  }
}
// Account Credits & CO-OP Menu Bar Boxes ENDS

.navbar-user {
  cursor: pointer;
}

.navbar-user:hover {
  opacity: 0.8;
}

.navbar-location {
  min-width: 250px;
}

.my-account {
  margin-right: 10px;
  padding: 5px 10px;
}

@media (max-width: 768px) {
  .navbar-location {
    min-width: 180px;
  }
  .my-account {
    margin-right: 0;
  }
}

.navbar-location select {
  background: transparent;
  padding: 3px 0;
  color: #777;
  border-radius: var(--bradius);
  margin-bottom: 2px !important;
}

.nav-search {
  border: 1px solid black;
  border-radius: var(--bradius);
  padding: 0 10px;
  width: 100%;
}

.filter-container {
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.product-list .nav-search {
  margin: 20px 0px;
}

.product-list .nav-search input {
  text-align: initial;
  padding-left: 0;
}

.product-link {
  text-decoration: underline dotted !important;
}

.product-detail-page .rw-widget-picker > * {
  display: contents;
}

.product-spec-modal.modal-dialog {
  max-width: 600px;
}

.product-spec-modal-close {
  position: absolute;
  right: 15px;
}

.iron-product-item .price-wrap span {
  font-size: 0.8rem;
}

input[type='checkbox'].custom-checkbox.form-check-input {
  margin-left: -2.4rem;
}

input[type='radio'].custom-radio.form-check-input {
  margin-left: -2.4rem;
}

.out-of-stock-container {
  width: 100%;
}

.out-of-stock {
  float: right;
  color: #ff4d4d;
  font-weight: 600;
  margin-right: 15px;
}

.product-detail-page-error-message {
  text-align: center;
  margin-top: 5px;
  font-size: 12px;
  color: black;
}

.rw-widget-picker {
  padding-left: 10px;
}

.selected_category span svg {
  color: #000;
}

.nav-search svg {
  color: black;
  cursor: pointer;
}

.nav-search svg:hover {
  opacity: 0.6;
}

.filter-container .nav-search {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0;
  padding-left: 0;
}

.filter-container .nav-search ::placeholder {
  color: #000 !important;
}

.payment-deductions {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.deduction {
  color: #c52d07;
}

.credit-checkbox {
  position: absolute !important;
  top: 0px;
  right: 5px;
}

.credit-balance {
  position: relative;
  font-size: 0.875rem;
  margin-bottom: 20px;
  padding: 5px 23px;
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.credit-balance.active {
  font-size: 0.875rem;
  margin-bottom: 20px;
  padding: 5px 23px;
  box-shadow:
    0px 1px 3px 0px #850017,
    0px 1px 1px 0px #850017,
    0px 2px 1px -1px #850017;
}

.credit-balance p {
  margin-bottom: 4px;
}

.green-text {
  color: var(--mf-green-co);
  h6 {
    color: var(--mf-green-co);
  }
}

.change-fields {
  color: #007bff;
}

.change-fields:hover {
  opacity: 0.8;
}

// YOP BAR LINKS BEGINS
#headerlinks {
  // display: flex;
  align-content: center;
  align-items: center;
  z-index: 11; // to display over search bar
  li {
    // display: flex;
    align-content: center;
    align-items: center;
  }
}
// YOP BAR LINKS ENDS

// CHECKOUT ADDRESS MANAGEMENT BEGINS

.address-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.address-btn-box {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  align-content: flex-end;
}

.address-information {
  border: 1px solid #ccc;
  border-radius: var(--bradius);
  padding: 10px 15px;
  position: relative;
  margin-bottom: 1.25em;
  background-color: white;
}

.address-edit {
  color: #007bff;
  right: 10px;
  top: 20px;
  position: absolute;
  cursor: pointer;
}

.address-edit:hover {
  opacity: 0.8;
}

#gaddresslookup-1 {
  margin-top: 1rem;
}

#gaddresslookup-1 input {
  border: 1px solid #c4c4c4;
  border-radius: var(--bradius);
  padding-top: 25px;
  padding-bottom: 25px;
}

.address-information .default_address {
  float: left;
  margin-left: -15px;
  margin-top: -10px;
  margin-bottom: 40px;
  margin-right: 0;
}
.highlight-address {
  background: #ddd;
}

// CHECKOUT ADDRESS MANAGEMENT ENDS

.product-table {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.bundle-dropdown-item {
  font-size: 0.83rem;
}

.form-check-input {
  margin-top: auto !important;
}

input[type='checkbox'],
input[type='radio'] {
  align-items: center;
  align-content: center;
  display: flex;
}

.form-check-label {
  align-items: center;
  align-content: center;
  display: flex;
}

.rw-input.rw-dropdown-list-input {
  font-size: 0.875rem;
}

.rw-popup-container {
  top: -10px;
}

.expand-panel {
  width: 100%;
  padding-bottom: 12px;
  margin-bottom: 10px !important;
}
.expand-panel.individual {
  .bnd-form-control-text {
    margin-top: 10px;
    box-shadow: none;
  }
}
.bnd-grid-container {
  padding-right: 0px !important;
}
.radio {
  width: 100%;
}

.bnd.button {
  float: right;
  align-self: center;
}
.bnd-btn-grid {
  padding-top: 8px;
}
.config-container {
  padding: 0 24px;
}

.number-format-label {
  margin-bottom: 0px;
  color: #747474;
  padding-left: 14px;
  font-weight: 300;
}
.format-label {
  margin-bottom: 0px;
  color: #747474;
  font-weight: 300;
}

.vanity-phone {
  margin-bottom: 0px;
  color: #747474;
  padding-left: 100px;
  font-weight: 300;
}

.address-format-label {
  align-items: center;
  text-align: right;
  color: #747474;
  font-weight: 300;
}

.address-format-error {
  align-items: center;
  text-align: right;
  font-weight: 300;
}

.number-format-field {
  padding: 12px;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: var(--bradius);
  box-shadow: none;
}

.state-dropdown {
  background-color: white;
  color: #222222;
  font-size: 18px;
  width: 100%;
  height: 50px;
  margin: 8px 0;
}

.config-option {
  display: inline-block;
  margin-right: 15px;
  width: 36%;
}

.config-option label {
  margin-right: 6px;
}

.config-label {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.7);
}
.config-child-dropdown {
  width: 100%;
  margin-bottom: 40px;
  padding-right: 10px !important;
}
.config-dropdown {
  margin-bottom: 40px;
}

.slider-quick-view {
  opacity: 0;
  transition: 0.3s;
}

.product-slider .product-slider-title {
  text-align: center;
}

.product-slider .product-slider-categories {
  text-align: center;
}

.product-slider .product-slider-selected-category {
  color: #850017;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.product-slider .product-slider-category {
  color: #777;
}

.product-slider .product-slider-categories button:focus {
  outline: 0;
}

.product-slider .iron-post-item h4 {
  position: absolute;
  top: 0;
  left: 10px;
}

.product-slider .iron-post-item p {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2em;
}

.product-slider .iron-post-item h4 a {
  color: #fff;
}

.product-slider .iron-post-item p {
  color: #fff;
}

.product-slider .iron-post-item a:hover {
  text-decoration: none;
}

.product-slider .iron-post-item .slider-quick-view {
  position: absolute !important;
  bottom: 15px;
}

.product-slider .iron-post-item .text-main {
  line-height: 0 !important;
}

.product-slider .iron-post-item:hover {
  .slider-quick-view {
    opacity: 1;
    transition: 0.3s;
  }
}

.product-slider .iron-btn-grp a {
  color: $active;
}

.product-slider a:hover {
  color: $active;
}

.iron-cta-item.custom-cta {
  position: relative;
}

.iron-cta-item.custom-cta:hover {
  box-shadow:
    0 0 16px 0 rgba(0, 0, 0, 0.12),
    0 16px 16px 0 rgba(0, 0, 0, 0.24) !important;
}

.iron-cta-item.custom-cta h4 {
  position: absolute;
  top: 10px;
  left: 15px;
  color: #fff;
}

.iron-cta-item.custom-cta p {
  position: absolute;
  top: 40px;
  left: 15px;
  color: #fff;
}

.contact-numbers {
  display: initial;
  padding-left: 2px;
}

.contact-icon {
  background: var(--mf-primary-color);
  width: 34px;
  height: 34px;
  padding: 3px 5px;
  margin-right: 5px;
  display: inline-block;
  border-radius: var(--bradius);
}

.iron-contact-info-wrap .contact-icon * {
  color: var(--mf-white);
}

.btn {
  text-transform: capitalize;
}

.btn-active {
  background-color: #8c1414;
}

.basket-checkout-btn .btn-active {
  background-color: #8c1414;
}

.fixed-menu-cart {
  text-align: right;
  // margin-top: 12px;
}

.fixed-menu-cart button.icon-btn {
  float: right;
}

.category-filter {
  border-radius: var(--bradius);
  padding: 0;
  width: 230px;
  display: block;
  margin: auto;
  min-height: 100px;
  font-weight: 900;
}

.individual-category {
  width: 100%;
  height: 33px;
}

.child-category {
  padding-left: 20px;
  width: 100%;
  height: 33px;
}

.slider-featured-products-wrapper {
  display: block;
  width: 230px;
  height: fit-content;
  margin: auto;
  padding: 0;
  border-radius: var(--bradius);

  h5 {
    text-align: center;
  }

  .slider-featured-products {
    h5 {
      text-align: left;
    }
  }
}

.default-address-book {
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin-top: 20px;
}

.default-address-book ul {
  list-style: none;
}

.edit-address {
  cursor: pointer;
  font-size: 18px !important;
  position: absolute;
  right: 24px;
  top: 21px;
}

.edit-address:hover {
  opacity: 0.7;
}

.address-action {
  cursor: pointer;
  font-size: 18px !important;
  position: absolute;
  right: 53px;
  top: 21px;
}

.address-action:hover {
  opacity: 0.7;
}

.address-box {
  position: relative;
  padding: 10px;
}

.address-box .address-title {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 5px;
  min-height: 28px;
}

.address-box ul {
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  background: #fff;
  padding: 10px;
  list-style: none;
  border-radius: var(--bradius);
}

.door-hub-address-btn {
  font-size: 15px;
  background: #6994ca;
  color: #fff;
  border: none;
  border-radius: var(--bradius);
  padding-left: 5px;
  padding-right: 5px;
}

.modal .modal-dialog.specialsModal {
  margin-top: 20%;
  width: 700px;
  margin-top: 270px;
  height: 325px;
  background-size: contain;
  background-repeat: no-repeat;
}

.specialsModal .modal-content {
  background: none;
  border: none;
  //height: 100%;
  border-radius: 0;
  position: relative;
}

.specialsModal .modal-close {
  align-self: flex-end;
  width: 40px;
  color: black;
  font-size: 2em;
  margin: 5px;
  background: no-repeat;
  border: none;
  cursor: pointer;
}

.specialsModal .modal-dont-show {
  border-radius: 0;
  border: none;
  bottom: 0px;
  position: absolute;
  width: 100%;
  padding: 0px 15px;
}

.modal-content .modal-body .modal-hide-html {
  display: none;
}

// Customer account pages

.account-navigation .account-current {
  color: #000;
}

.account-navigation ul li {
  list-style: none;
  padding: 6px 0;
  border-bottom: 1px solid #ddd;
  margin-right: 20px;
  color: #808080;
  cursor: pointer;
}

.error-toast {
  background-color: #cc0000;
  margin-top: 200px;
}

.success-toast {
  background-color: #22c912;
  margin-top: 200px;
}

.iron-dashboard-page-wrap {
  margin-bottom: 70px;

  h4 {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .welcome-dashboard-message {
    background-color: #dff0d7;
    p {
      color: #3d763c;
      padding: 15px;
    }
  }

  .recent-orders-wrap-table {
    overflow-x: scroll;
  }

  .recent-orders-wrap {
    .recent-orders-wrap-header {
      padding-bottom: 20px;
      &:after {
        /* Clearfix */
        content: '';
        display: table;
        clear: both;
      }
      h5 {
        float: left;
      }
      a {
        float: right;
        color: $primary;
      }
    }
    .recent-orders-wrap-table {
      margin: 20px auto;
      width: 100%;
    }
  }

  .previous-orders-wrap {
    .previous-orders-wrap-table {
      margin: 20px auto;
      overflow-x: scroll;

      .navigation-button {
        flex-shrink: 0;
        margin-left: 20px;
      }
    }

    #gridContainer,
    .dx-datagrid {
      // border-radius: 15px;
      // -webkit-border-radius: 15px;
      // -moz-border-radius: 15px;
      // -ms-border-radius: 15px;
      // -o-border-radius: 15px;
      box-shadow:
        0 1px 5px 0 rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12);
    }

    .dx-datagrid {
      padding: 10px;
    }
  }

  .recent-orders-wrap {
    .recent-orders-wrap-table table th,
    td {
      white-space: nowrap;
    }
  }

  .previous-orders-wrap {
    .previous-orders-wrap-table table th,
    td {
      white-space: nowrap;
    }
  }

  .previous-orders-wrap .previous-orders-wrap-table tfoot select {
    margin-bottom: 0 !important;
  }

  .credit-information-row {
    margin-right: 15px;
  }

  .credit-information-grid {
    box-shadow:
      0 1px 5px 0 rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12);
    background: #fff;
    padding: 10px;
    border-radius: var(--bradius);
    margin-left: 15px;
    margin-right: 15px;
  }

  .order-show-container {
    margin-left: 0px;
    margin-right: 15px;
    padding-left: 0px;
    padding-right: 0px;
  }

  table .order-cost-order-show table-sm {
    width: 49%;
    float: left;
  }

  table .checkout-information-order-show table-sm {
    width: 49%;
    float: right;
  }

  .contact-information-wrap {
    max-width: 100%;

    .contact-information-grid {
      padding: 15px;
      max-width: 100%;

      ul {
        box-shadow:
          0px 1px 5px 0px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 3px 1px -2px rgba(0, 0, 0, 0.12);
        background: #fff;
        padding: 10px;
        list-style: none;
        height: 80px;
      }

      ul li {
        padding-bottom: 6px;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 768px) {
  .iron-dashboard-page-wrap {
    .my-account h4 {
      margin-bottom: 20px;
    }
    .my-account {
      padding-right: 0px;
    }
    .my-dashboard h4 {
      margin-bottom: 20px;
    }
  }

  .address-container {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .address-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}

@media (max-width: 932px) {
  .iron-dashboard-page-wrap {
    .checkout-information-order-show {
      width: 100%;
    }
    .order-cost-order-show {
      width: 100%;
    }
  }
}

@media (max-width: 430px) {
  .iron-dashboard-page-wrap {
    .checkout-information-order-show {
      width: 100%;
    }
  }
}

.shipping-error {
  background-color: #ff9966;
  padding: 8px 10px 5px;
  margin-bottom: 10px;
  p {
    margin-top: 5px;
  }
}

.maintenance-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 150px;
}

.current-environment {
  background-color: white;
  color: red;
  font-size: 30px;
  text-align: center;
  padding: 20px;
}

// FLOATING CATEGORY MENU BEGINS
.floating-menu {
  display: flex;
  align-content: center;
  align-items: center;
}
// FLOATING CATEGORY MENU ENDS

// MENUBAR CART BEGINS
.cartItemThumbnail {
  width: 100px;
  border-right: 1px solid var(--mf-lightgrey);
}
.cartItemDetails {
  padding: 0.7em 0;
  vertical-align: middle !important;
  font-size: 1em;
  width: 40%;
}
.cartItemExtraInfo > td,
.dpInfoTopCart {
  font-size: 0.95em !important;
  line-height: 1.5 !important;
  padding: 0 !important;
}
.lineItemQuantity {
  width: 15% !important;
  max-width: 50px !important;
  min-width: 30px !important;
}
.cartItemRow:hover {
  background-color: var(--mf-root-background);
}
.popover-header::before {
  display: none !important;
}

// MENUBAR CART ENDS

// CART PAGE BEGINS
.cart-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 1.25em auto !important;
  align-content: center;
}

.cart-thumbnail-flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}
.lineItemPriceText {
  text-align: right;
}
.cart-price-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  margin-left: 0px !important;
}
.cart-price-container {
  padding: 0 !important;
  margin-left: 1.5625rem;
  text-align: right;
}

.right-panel-promotions-summary {
  min-height: 100vh;
}

.sticky-summary {
  position: fixed !important;
  width: 25%;
  max-width: 350px;
  margin: 1.25em auto !important;
}
.order-summary {
  padding: 1em;
  background-color: var(--mf-white);

  div {
    padding: 0;
    margin: 1em auto;
  }
}
.cart-info-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.promo-code-box {
  display: block;
  height: auto;
  min-height: 167px;

  .sticky-summary {
    margin-top: 0px !important;
  }
}
.setup-charge-cart {
  font-size: 0.85rem;
}

// CART PAGE ENDS

// CHECKOUT PAGE BEGINS
.ckout-header {
  // margin: -25px -16px 0px -16px !important;
  background: var(--mf-lightgrey) !important;
  border-radius: 4px 4px 0px 0px;
  padding: 13px !important;

  h6,
  p {
    margin: 0;
  }
}
.ckout-header-billing {
  // margin: 0px -15px 0px -15px;
  background: var(--mf-lightgrey);
  padding: 13px;
}
// CHECKOUT PAGE ENDS

.sidebar-links a {
  color: white !important;
  font-size: x-large;
  padding-left: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  margin: 0px 30px 0px 30px;
  font-family: 'Roboto', sans-serif;
}

.iron-app-logo-sidebar {
  padding: 30px;
}

.hamburger-fixed-header {
  margin-right: 10px;
  margin-top: 10px;
}

.modal.show .modal-dialog {
  width: 100%;
}

.specialsModal .modal-close {
  position: absolute;
  right: 5px;
}

.preview-modal {
  max-width: 75vw !important;
}

.bundle-proof-incomplete {
  background-color: #ffcccb !important;
}
.bundle-proof-complete {
  background-color: #e5ffcc !important;
}
.address-checkbox {
  float: right;
  margin-right: -14px;
}

.iron-cart-wrapper button.cart-btn {
  background: transparent;
  border: 0;
}

.dependency-title {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
}

.customField {
  margin: 25px 0;
}

.dependency-error-list {
  list-style: none;
}

.tier-price-text {
  padding: 10px;
}

.product-flag-container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.sale-price-product-card-box,
.requires-approval-product-card-box,
.tier-price-product-card-box,
.box-set-product-card-box {
  padding: 5px 10px;
  color: white;
  font-size: 11px;
  border-radius: 0px 2px 2px 0px;
  width: 140px;
  // min-width: 80px;
  text-align: center;
  font-weight: bold;
}

.sale-price-product-card-box {
  background-color: #ffa200;
  font-size: 15px;
  min-height: 20px;
}

.requires-approval-product-card-box {
  background-color: #da0e0e;
}

.requires-approval-product-page-box {
  bottom: 0;
  left: 0;
  position: absolute;
}

.tier-price-product-card-box {
  background-color: #018541;
  // max-width: 80px;
}

.box-set-product-card-box {
  background-color: #0633e8;
}

.backorder-stock-text-card-box {
  position: absolute;
  // top: 87%;
  left: 0%;
  padding: 5px 10px;
  background-color: #0083f7;
  color: white;
  font-size: 11px;
  // max-width: 80px;
  border-radius: var(--bradius);
}

#product-card-info tbody td {
  margin: auto;
  text-align: center;
  justify-content: center;
  width: 50%;
  vertical-align: bottom;
}

#product-card-info#price-field-primary {
  white-space: nowrap;
}

div span.display-price {
  font-size: large;
  font-weight: 500;
  line-height: 24px;
}

#price-field-primary {
  padding-right: 5px;
}

#price-field-secondary {
  padding-left: 5px;
  border-left: grey 1px dotted;
}

.product-info-theme {
  color: $active;
  font-family: $roboto;
}

#product-card-info {
  text-align: center;
  width: 100%;
}

.product-card-info-extended {
  min-height: 160px;
}

.product-card-info-short {
  min-height: 120px;
}

.iron-banner-slider-v3 .slick-slide {
  padding: 2px;
}

.max {
  padding-bottom: 10px;
}

.grey-out-text {
  color: #888;
}

/** bundle styling */

.bundle-product-checkbox.form-check-input {
  margin-left: 0.3rem;
  margin-top: -0.8rem;
}
.bundle-configurable {
  margin-bottom: 1.2rem;
}

.bundle-configurable-radio.row {
  margin-bottom: 1.2rem;
  margin-left: 0.2rem;
}

.bundle-configurable-simple.row {
  margin-bottom: 1.2rem;
  margin-left: -2rem;
}

.bundle-simple.row {
  margin-bottom: 1.2rem;
}

.bundle-radio-product-show.btn-secondary {
  font-size: 0.85rem;
}

.bundle-radio-product-show.btn {
  border-radius: 0;
}
.bundle-radio-dropdown {
  margin-top: 0.38rem;
}
.bundle-radio {
  .card-body {
    padding: 0.75rem 1rem;
  }
}

// CREDTI CARDS PAYMENT METHOD BEGINS
.rccs {
  width: 100% !important;
}
.rccs__card {
  width: 100% !important;
  max-width: 250px !important;
  min-width: 219px !important;
}
.cc-form-flex {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: center;
}

.cc-exp-date {
  width: 100%;
  max-width: 85px;
}

// CREDTI CARDS PAYMENT METHOD ENDS

.btn {
  font-size: 0.875rem !important;
}

.button.btn-lg.btn {
  font-size: 0.875rem;
}

.btn-secondary {
  color: #fff;
}

.cart-product-sku {
  font-size: 0.875rem;
}

.cart-product-artwork {
  font-weight: bold;
  font-style: italic;
}

.cart-product-artwork span {
  font-size: 0.875rem;
  font-weight: normal;
  font-style: normal;
}

.checkout-product-p {
  margin-bottom: 0;
}

.mb-10.artwork-thumbnail {
  width: 300px;
}
.mb-10.artwork-thumbnail2 {
  width: 300px;
}

.reset-selection {
  color: var(--mf-danger) !important;
  font-size: 1rem;
}
.reset-selection:hover {
  color: var(--mf-primary-color) !important;
}

.template-image {
  padding: 0;
  border: none !important;
  background: none !important;
  border-radius: 10% !important;
  -webkit-border-radius: 10% !important;
  -moz-border-radius: 10% !important;
  -ms-border-radius: 10% !important;
  -o-border-radius: 10% !important;
}

.template-image-selected {
  border: 2px solid #ffffff !important;
  box-shadow: 0 0 0 3px #c7c7c7;
}

.template-non-selected-image:hover,
.template-non-selected-image:active,
.template-non-selected-image:focus {
  border: 2px solid #ffffff !important;
  box-shadow: 0 0 0 3px #c7c7c7;
}

.m40px {
  margin: 40px 0px;
  padding-left: 16px;
}

.cell-right {
  text-align: right !important;
}

.cell-left {
  text-align: left !important;
}

.addedsuccesbtn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.addtocart-modal-btn {
  line-height: 1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.addtocart-modal-btn:hover {
  text-decoration: none !important;
}

.sweet-alert .btn-warning {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: white !important;
}

.sweet-alert .btn-primary.swapped-btn {
  border: 2px solid var(--mf-primary-color) !important;
  background-color: var(--mf-transparent) !important;
  color: var(--mf-primary-color) !important;
  padding: 0.5em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  line-height: 2 !important;
}

.price-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 150%;
  margin-right: 1.2em;
}
.price-wrap-sale {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 150%;
  max-width: 150%;
  margin-right: 1.2em;
}
.float-b {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.float-u {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-end;
  justify-content: flex-start;
}

.coop-divider {
  border-left: 2px solid #e6e6e6;
  padding-left: 1.2em;
}

.p0 {
  padding: 0 !important;
}

// .form-control, .number-format-field{
//   width:75% !important;
// }

.outline-cs {
  border-color: var(--mf-primary-color) !important;
  color: var(--mf-primary-color) !important;
  border: 2px;
}

.outline-cs:hover {
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
}

.mf-img-container {
  background-color: var(--mf-white);
}

.mf-additional-img-container {
  background-color: var(--mf-white);
}

.product-img {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 300px;
  object-fit: cover;
}
// MF UTILITIES BEGINS

.mamrgin-fix-children > p {
  margin-bottom: 0;
}

.img-border {
  border-radius: var(--bradius);
  overflow: hidden;
}

.txt-white {
  color: var(--mf-white);
}

.box-shadow {
  box-shadow: 1px 1px 9px #00000026;
  border-radius: var(--bradius);
}
.p-075 {
  padding: 0.75em 0;
}

.bradius {
  border-radius: var(--bradius);
  -webkit-border-radius: var(--bradius);
  -moz-border-radius: var(--bradius);
  -ms-border-radius: var(--bradius);
  -o-border-radius: var(--bradius);
}

.br-8 {
  border-radius: 8px;
}
.br-4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.w-inherit {
  width: inherit;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-underline {
  text-decoration: underline;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex-align-center {
  align-items: center;
  align-content: center;
}
.f-wrap {
  flex-wrap: wrap;
}
.flex-align-center {
  align-items: center;
  align-content: center;
}
.f-no-wrap {
  flex-wrap: nowrap !important;
}
.flex-space-between > h5 {
  margin-bottom: 0px;
}
.white-background {
  background-color: var(--mf-white);
}
.vertical-rule {
  border-left: 2px solid var(--mf-lightgrey);
  height: 75px;
  margin: 0 8px;
}
.m125-auto {
  margin: 1.25em auto !important;
}
.mobile-flex {
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

// MF UTILITIES ENDS

// .breadcrumbs-bar{
//   line-height: 1.5;
//   height: 4em;
//   padding: 0px 25px;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   align-items: center;
// }
.w125px {
  width: 125px;
}
.mf-sticky {
  display: block;
  position: sticky;
  top: 0;
}

.product-gallery-item {
  margin: 20px 8px;
}

.btn-lg-height {
  min-height: 0 !important;
}

.increment-quantity > span {
  width: 100%;
}

.min-height-3125 {
  min-height: 3.125rem;
}

.MuiCard-root-category {
  width: 100%;
  max-width: 300px;
}

.mobile-sidebar-menu {
  div {
    background-color: var(--mf-primary-color) !important;
    width: 100%;
    max-width: 300px;
    a {
      font-size: 15px;
      line-height: 2.25;
      color: var(--mf-white);
    }
  }
}

.additionalimages {
  display: grid;
  grid-template-columns: repeat(auto-fill, 135px);
  flex: auto;
}

.popover {
  // max-width: 500px !important;
  width: 100%;
  max-height: 532px;
  overflow: auto;
  overflow-x: hidden;
  // transform: translate3d(1060px, 73px, 0px) !important;
}

.cartpopperitems {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: none !important;
  line-height: 1.75;
  padding: 0.15em !important;
}

.poppercartbody > tr:nth-child(even) {
  background-color: #eeeeee;
}

.cart-popper-shadow > .popover {
  box-shadow:
    0 3px 4px 4px rgb(0 0 0 / 3%),
    0 2px 8px 0px rgb(0 0 0 / 2%),
    0 -3px 15px 8px rgb(0 0 0 / 7%) !important;
  border: none;
}

.addressbox,
.tier-pricing-container,
.description-container,
.included-bundle,
.setup-charge-container {
  background-color: var(--mf-lightgrey);
  padding: 1.5em;
  margin: 1.25em 0;
  width: 100%;
  border-radius: var(--bradius);
}

.description-container li {
  text-indent: -20px;
  list-style-position: inside;
  padding-left: 32px;
}

.setup-charge-container-bundle {
  background-color: #eeeeee;
}

.short-description {
  line-height: 1.3;
  margin: 0.8rem 0;
  height: auto;
}

// BUNDLE PRODUCTS PRODUCT PAGE BEGINS
.bundleconfig {
  background-color: var(--mf-lightgrey) !important;
  border: none !important;
  .bundleconfig-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    background-color: var(--mf-lightgrey) !important;
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;
  }
}

// BUNDLE PRODUCTS PRODUCT PAGE ENDS

// DP ADDRESS FORM
.dp-address-form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

// MF PRIMARY AND SECONDARY BUTTONS BEGINS
// Please use the following classes for buttons

.mf-primary-nav-btn,
.mf-primary-nav-btn:focus,
.mf-primary-nav-btn:active {
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
  padding: 0.3em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  border: 2px solid var(--mf-primary-color) !important;
  height: 39px !important;
}

@media (max-width: 768px) {
  .mf-primary-nav-btn {
    height: 42px !important;
  }
}

.mf-primary-btn,
.mf-primary-btn:focus,
.mf-primary-btn:active {
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
  padding: 0.5em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  border: 2px solid var(--mf-primary-color) !important;
  line-height: 2 !important;
}

.mf-primary-btn:hover {
  background-color: var(--mf-transparent) !important;
  color: var(--mf-primary-color) !important;
  border: 2px solid var(--mf-primary-color) !important;
}

.mf-outline-btn {
  border: 2px solid var(--mf-primary-color) !important;
  background-color: var(--mf-transparent) !important;
  color: var(--mf-primary-color) !important;
  padding: 0.5em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  line-height: 2 !important;
}
.mf-outline-btn:hover,
.mf-outline-btn:focus,
.mf-outline-btn:active {
  border: 2px solid var(--mf-primary-color) !important;
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
}

.mf-outline-sm-btn {
  background-color: var(--mf-transparent) !important;
  border: 2px solid var(--mf-primary-color) !important;
  color: var(--mf-primary-color) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: var(--bradius);
}

.mf-outline-sm-btn:hover,
.mf-outline-sm-btn:focus,
.mf-outline-sm-btn:active {
  border: 2px solid var(--mf-primary-color) !important;
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
}

.mf-danger-btn {
  border: 2px solid var(--mf-danger) !important;
  background-color: var(--mf-danger) !important;
  color: var(--mf-white) !important;
  padding: 0.5em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  line-height: 2 !important;
}

.mf-danger-btn:hover,
.mf-danger-btn:focus,
.mf-danger-btn:active {
  background-color: transparent !important;
  color: var(--mf-danger) !important;
}

.mf-outline-danger-btn {
  border: 2px solid var(--mf-danger) !important;
  background-color: var(--mf-transparent) !important;
  color: var(--mf-danger) !important;
  padding: 0.5em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  line-height: 2 !important;
}

.mf-outline-danger-btn:hover,
.mf-outline-danger-btn:focus,
.mf-outline-danger-btn:active {
  background-color: var(--mf-danger) !important;
  color: var(--mf-white) !important;
}

.mf-outline-danger-sm-btn {
  background-color: var(--mf-transparent) !important;
  border: 2px solid var(--mf-danger) !important;
  color: var(--mf-danger) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: var(--bradius);
}

.mf-outline-danger-sm-btn:hover,
.mf-outline-danger-sm-btn:focus,
.mf-outline-danger-sm-btn:active {
  border: 2px solid var(--mf-danger) !important;
  color: var(--mf-white) !important;
}

.mf-outline-warning-btn {
  border: 2px solid var(--mf-warning) !important;
  background-color: var(--mf-transparent) !important;
  color: var(--mf-warning) !important;
  padding: 0.2em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  line-height: 2 !important;
}

.mf-outline-warning-btn:hover,
.mf-outline-warning-btn:focus,
.mf-outline-warning-btn:active {
  background-color: var(--mf-warning) !important;
  color: var(--mf-white) !important;
}

.mf-warning-btn {
  border: 2px solid var(--mf-warning) !important;
  background-color: var(--mf-warning) !important;
  color: var(--mf-white) !important;
  padding: 0.2em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  line-height: 2 !important;
}

.mf-warning-btn:hover,
.mf-warning-btn:focus,
.mf-warning-btn:active {
  background-color: var(--mf-warning-dark) !important;
  border: 2px solid var(--mf-warning-dark) !important;
  color: var(--mf-white) !important;
}

.mf-warning-sm-btn {
  background-color: var(--mf-warning) !important;
  border: 2px solid var(--mf-warning) !important;
  color: var(--mf-white) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: var(--bradius);
}

.mf-warning-sm-btn:hover,
.mf-warning-sm-btn:focus,
.mf-warning-sm-btn:active {
  background-color: var(--mf-warning-dark) !important;
  border: 2px solid var(--mf-warning-dark) !important;
  color: var(--mf-white) !important;
}

.mf-secure-co-btn,
.mf-secure-co-btn:focus,
.mf-secure-co-btn:active {
  background-color: var(--mf-green-co) !important;
  color: var(--mf-white) !important;
  padding: 0.5em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  border: 2px solid var(--mf-green-co) !important;
  line-height: 2 !important;
}

.mf-secure-co-btn:disabled {
  background-color: rgb(141, 141, 141) !important;
  border: 2px solid rgb(141, 141, 141) !important;
}

.mf-secure-co-btn:hover {
  background-color: var(--mf-transparent) !important;
  color: var(--mf-green-co) !important;
  border: 2px solid var(--mf-green-co) !important;
}

.mf-secure-co-btn:disabled:hover {
  background-color: var(--mf-transparent) !important;
  color: var(--mf-danger);
  border: 2px solid var(--mf-danger) !important;
}

.alert-width-btn {
  width: 150px;
  text-decoration: none !important;
}

.contact-btn {
  padding: 6px 10px 6px 6px !important;
}

.contact-btn:hover {
  background-color: var(--mf-transparent);
  color: var(--mf-primary-color);
  border: 2px solid var(--mf-primary-color);
  .MuiButton-label {
    color: var(--mf-primary-color) !important;
  }
}

.mf-system-sm-btn {
  background-color: var(--mf-primary-color) !important;
  border: 2px solid var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: var(--bradius);
}

.mf-system-link-adjust {
  display: inline-flex;
  padding: 0.24rem 0.5rem !important;
  text-decoration: none !important;
}

.mf-system-sm-btn:hover {
  background-color: var(--mf-transparent) !important;
  color: var(--mf-primary-color) !important;
  border: 2px solid var(--mf-primary-color) !important;
}

.mf-system-outline-sm-btn {
  background-color: var(--mf-transparent) !important;
  border: 2px solid var(--mf-primary-color) !important;
  color: var(--mf-primary-color) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: var(--bradius);
}

.mf-system-outline-sm-btn:hover,
.mf-outline-sm-btn:focus,
.mf-outline-sm-btn:active {
  border: 2px solid var(--mf-primary-color) !important;
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
}

// MF PRIMARY AND SECONDARY BUTTONS ENDS

.manageadress-box {
  background-color: var(--mf-lightgrey);
  // padding: 12px 5px 12px 5px;
  // margin: 10px 0 20px 0px;
  border-radius: var(--bradius);
}

.cartpopperdeletitem {
  width: 100%;
  min-height: 105px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0.25em !important;
}

.tier-pricing-list {
  list-style: none;
  line-height: 1;
  margin: 0.8rem 0;
  height: auto;
}

.tier-pricing-list h6 {
  font-weight: normal;
  font-size: 0.95em;
  line-height: 1;
  text-transform: initial;
}

.qtyinputbox {
  color: #495057;
  background-color: var(--smart-background);
  background-clip: padding-box;
  border: 1px solid var(--smart-border);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 100%;
  display: block;
  height: auto;
  border-radius: var(--bradius);
  line-height: 1.75;
  padding: 0.475rem 0.75rem;
  font-weight: 400;
}

.checkout-cart-width {
  width: 50%;
}

.checkout-card-display {
  display: flex;
}

.search_bar_input:focus {
  z-index: 10;
  color: #495057 !important;
  box-shadow: 0 0 0 9999px #2c2b2b70 !important;
}

.search_bar_button {
  position: relative;
  height: inherit;
  background-color: var(--mf-primary-color) !important;
  border-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
  margin-right: 25px;
}
.search_bar_button_fixed {
  background-color: var(--mf-primary-color) !important;
  border-color: var(--mf-primary-color) !important;
}
.search_bar_button_small {
  position: relative;
  height: inherit;
  background-color: var(--mf-primary-color) !important;
  border-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
}

.products-container .product-search {
  flex-wrap: nowrap;
}

.top-links-area-mobile .mobile-credits #no-credits-popover .credits-container {
  height: 46px;
}

.suggestions_container {
  position: absolute;
  z-index: 10 !important;
  background-color: white;
  width: inherit;
  border-radius: var(--bradius);
  -webkit-border-radius: var(--bradius);
  -moz-border-radius: var(--bradius);
  -ms-border-radius: var(--bradius);
  -o-border-radius: var(--bradius);
}

.suggestions_container .suggestions {
  // position:absolute;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}
.suggestions_selected {
  background-color: rgba(220, 220, 220, 0.853);
}

.suggestions_container .suggestions:hover {
  background-color: rgba(220, 220, 220, 0.853);
}

// OVERWRITES BEGINS

// OVERWRITES ENDS

// MEDIA QUERIES

// Large
@media only screen and (max-width: 1024px) {
  .display-desktop {
    display: none;
  }
  .display-mobile {
    display: block;
  }
  .creditcard-payment {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    width: 100%;
  }
  .checkout-cart-width {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 0;
  }
  .checkout-card-display {
    display: block;
  }
}

// Extra Large
@media only screen and (min-width: 1025px) {
  .display-mobile {
    display: none;
  }
  .display-desktop {
    display: flex;
  }
}

// Medium
@media only screen and (max-width: 767px) {
  .form-control,
  .number-format-field {
    width: 100% !important;
  }
  .iron-app-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .coupon-mobile {
    width: calc(100% - 63px) !important;
  }
}

// Small
@media only screen and (max-width: 576px) {
  .specialsModal {
    margin-left: 0 !important;
  }
  .cc-exp-date {
    width: 100%;
    max-width: 300px;
  }
}

// LARGE DEVICES
@media (max-width: 992px) {
  .cart-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin: 1.25em auto !important;
  }
  .cart-price-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    margin: 0 !important;
  }
  .cart-thumbnail-flex {
    height: 150px;
    width: auto;
    margin: auto;
  }
  .lineItemPriceText,
  .cart-price-box,
  .cart-price-container,
  .cartItemDetails {
    text-align: center;
  }
  .cart-price-container {
    margin-left: auto !important;
  }
  .cart-product-info {
    margin-bottom: 0.85em;
  }
}

// MEDIUM DEVICES
@media only screen and (max-width: 752px) {
  .sticky-summary {
    position: relative !important;
    width: 100%;
    max-width: 700px;
    margin: auto;
  }
}

.lh-1 {
  line-height: 1 !important;
}

.fw-right {
  text-align: right;
  padding: 1px !important;
}

.oder-error-paymrent-box {
  border-style: solid red;
}

.order-error-h3 {
  color: #363636;
}
.order-error-call-to-action {
  text-align: center;
  color: maroon;
  font-size: large;
}
.order-error-contact-info {
  text-align: center;
  color: maroon;
}

.small-help {
  font-size: 12.5px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}
