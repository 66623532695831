/*------------------------------------------------------------
  5.1.1 headers
-----------------------------------------------------------*/

//header one styling
.iron-header-v1 {
  box-shadow: none !important;

  .top-links-container {
    background-color: $footprint-secondary;
    padding: 5px 0;
    .top-links-area {
      display: block;
      padding: 0;
      float: right;
      position: relative;
      a {
        padding: 5px 0;
        color: #777;
      }
    }
    .top-links-icon {
      display: none;
      vertical-align: middle;
      font-size: 11px;
      padding: 7px 20px 7px 10px;
      position: relative;
      a {
        text-transform: uppercase;
        text-decoration: none;
        color: #777;
      }
    }
    .top-links-icon:before {
      content: '\25BC';
      display: block;
      position: absolute;
      right: 5px;
      top: 50%;
      font-family: 'porto';
      margin-top: -5px;
      color: #777;
      font-size: smaller;
    }
    .links {
      display: block;
      padding: 0;
      float: right;
      margin: 3px 0;
      li {
        font-size: 11px;
        padding: 0 15px;
        line-height: 19px;
        text-transform: uppercase;
        display: inline;
        color: #777;
      }
      li.first {
        border-left: none;
      }
      li.last:hover {
        text-decoration: underline;
      }
    }
    .welcome-msg {
      margin: 0 20px 0 0;
      text-align: left;
      float: right;
      font-size: 11px;
      line-height: 25px;
      text-transform: uppercase;
      color: #777;
    }
  }
  .iron-header-top {
    background-color: $footprint-secondary;
    .iron-header-widgets {
      .phone-number {
        margin-right: 20px;
        display: inline-block;
        vertical-align: middle;
        .icon-phone {
          margin-right: 5px;
          color: #777;
        }
        a {
          text-transform: uppercase;
          color: #777;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .iron-app-logo {
      text-align: center;
    }
  }
  .iron-header-bottom {
    min-height: 64px;
    background-color: $footprint-primary;
    justify-content: center;
  }
  .iron-header-menu {
    li {
      a {
        color: $footprint-secondary;
      }
      a:hover {
        background-color: $footprint-black;
      }
    }
  }
}

#logo-search-credit-cart.non-mobile-logo-row {
  margin-right: 15px;
  margin-left: 15px;
}

.top-links-area-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.top-links-area-mobile .mobile-top-row {
  width: 100%;
  margin-left: 0;
}

.top-links-area-mobile .drop-divider {
  border-left: 2px solid #ccc !important;
}

.top-links-area-mobile .mobile-account {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex: none;
  padding-right: 0;
  z-index: 11;
}

.top-links-area-mobile .mobile-credits {
  position: relative;
  padding-left: 0;
  margin-right: 20px;
}

.top-links-area-mobile .mobile-credits .credits-container {
  position: relative;
  height: 42px;
  width: 110px;
}

.account-drop-down-mobile {
  width: 300px;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

@media only screen and (min-width: 768px) {
  #headerlinks {
    display: inline-flex;
    p {
      color: #777;
    }
  }
}

@media only screen and (max-width: 767.98px) {
  #headerlinks {
    width: 250px;
    padding: 10px;
    padding-right: 20px;
  }
  p {
    color: #777;
  }
  .px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 767px) {
  .show {
    display: block !important;
  }
  .iron-header-v1 .top-links-container .welcome-msg {
    display: none;
  }
  .iron-header-v1 .top-links-container .top-links-icon {
    display: inline-block;
  }
  .iron-header-v1 .top-links-container .links {
    position: absolute;
    right: 0;
    margin: 0;
    padding: 5px 0;
    text-align: right;
    border: 1px solid #ccc;
    display: none;
    background-color: $footprint-secondary;
    z-index: 1;
  }
  .iron-header-v1 .top-links-container .links li {
    display: block;
    border: 0;
    padding: 0;
  }
  .iron-header-v1 .top-links-container .links li a {
    color: #777;
    padding: 10px 0px 10px 0px;
    color: $footprint-primary;
    display: block;
    font-weight: 400;
  }
  .iron-header-v1 .iron-header-top .iron-header-widgets .phone-number a {
    font-size: 11px;
  }

  .mobile-header-boxes {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    overflow-x: visible;
    white-space: nowrap;
  }
}
