// when this class is applied, the header is made visible and
// is fixed to the top of the page
.header-fixed .iron-fixed-header {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.iron-fixed-header {
  background-color: $footprint-primary;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out 0s;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  .iron-header-menu {
    li {
      padding: 0 5px;
      a {
        /*color: $base;*/
        color: $footprint-secondary;
        line-height: 4rem;
        &:after {
          bottom: 0;
        }
      }
      .sub-menu {
        top: 100%;
      }
    }
  }
}

.header-fixed .iron-fixed-header .iron-app-logo img {
  max-height: 40px;
}
