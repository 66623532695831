/*------------------------------------------------------------
  5.1.3 sidebar
-----------------------------------------------------------*/
//----- vertical-menu style -------
.sidebar-area {
  overflow-y: scroll;
  > div:nth-child(2) {
    background-color: #850016;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.vertical-menu {
  .iron-sidebar-menu {
    li {
      padding: 0 15px;
      height: auto;
      position: relative;
      display: block;
      a,
      span {
        line-height: 48px;
        color: $base;
        padding: 0;
        display: block;
        text-transform: capitalize !important;
        i,
        span {
          color: $base;
          display: flex;
          display: -ms-flexbox;
          justify-content: flex-start;
          -ms-flex-pack: start;
          align-items: center;
          -ms-flex-align: center;
        }
      }
      a {
        > span {
          &:nth-child(2) {
            display: none !important;
          }
        }
      }
      a {
        &:hover {
          background-color: transparent;
        }
      }
    }
    .sub-menu {
      padding: 0;
      display: block;
      transition: transform 0.3s ease-out 0s;
      li {
        padding-left: 35px;
        a {
          display: block;
        }
      }
      .object-list {
        &:before {
          display: none !important;
        }
      }
    }
    .sub-menu-child {
      li {
        padding-left: 60px;
      }
    }
    .sub-menu2 {
      padding: 0;
      display: block;
      transition: transform 0.3s ease-out 0s;
      .sub-menu {
        li {
          padding-left: 48px;
        }
      }
    }
    .list-item,
    .object-list {
      position: relative;
      &:after {
        position: absolute;
        content: '\F2FB';
        font-family: 'Material-Design-Iconic-Font';
        right: 1.5rem;
        top: 0;
        font-size: 1.25rem;
        bottom: 0;
        color: $base;
        display: flex;
        display: -ms-flexbox;
        align-items: center;
        -ms-flex-align: center;
        transform: rotate(0);
        transition: all 0.2s ease-in-out;
      }
    }
    .object-list {
      position: relative;
      padding-left: 60px;
      &:before {
        content: '\f301';
        color: #fff;
        position: absolute;
        left: 35px;
        line-height: inherit;
        top: 13px;
      }
    }
    .item-active {
      background-color: rgba(0, 0, 0, 0.2);
      &:after {
        transform: rotate(90deg);
      }
    }
  }
}
