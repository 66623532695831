//---- basic drop-down styling ----
.iron-dropdown {
  ul {
    width: 12.5rem;
    a {
      i {
        padding-right: 10px;
      }
    }
    li {
      height: auto;
      text-transform: capitalize;

      .img-wrap {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
    a:focus,
    a:hover {
      color: $dark;
    }
  }
}

//---- base font and color for dropdown ----
.base-text {
  span {
    font-size: 0.875rem;
    color: $base;
    text-transform: capitalize;
  }
  i {
    color: $base;
    margin-left: 0.3125rem;
    font-size: 1.5rem;
  }
  &:hover {
    background-color: transparent !important;
  }
}

.iron-app-logo img {
  max-height: 60px;
}

//---- logo and text styling ----
.iron-app-logo {
  h2 {
    font-size: 2.125rem;
  }
}
.iron-language-provider,
.iron-currency-provider {
  .base-text {
    span {
      font-weight: 400;
    }
  }
}

.cart-icon-justify-sm {
  justify-content: center;
}

.cart-icon-badge {
  position: absolute !important;
  top: 0px;
  right: 0px;
  font-size: 0.75rem;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--mf-secondary-color);
  color: white;
  box-shadow: 0 0 0 2px white;
}

@media only screen and (max-width: 767.98px) {
  .cart-icon-badge {
    top: -5px !important; /* Adjust the top position for smaller breakpoints */
  }
  .cart-icon-justify-sm {
    justify-content: end;
  }
  .header-col-sm {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .search-credit-row .col-12 {
    flex: 0 0 auto;
    max-width: none;
  }
  .search-credit-row {
    padding-right: 15px;
  }
  .search-credit-row .row {
    margin-right: 0px !important;
  }
  .search-credit-row .header-search-bar,
  .search-credit-row .credit-boxes {
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 10px;
  }

  .search-credit-row .header-search-bar {
    margin-left: 28px;
  }

  .credit-boxes {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
  }
  .search-credit-row .credits-container {
    flex-grow: 1;
    margin-right: 10px;
    max-width: 112px !important;
    min-width: 87px !important;
  }
  .search-credit-row .icon-btn {
    flex-shrink: 0;
  }
  .fixed-header-search-bar {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .header-search-bar {
    max-width: 550px !important;
    padding-right: 0px !important;
  }
  .cart-icon-justify-sm {
    max-width: 51px !important;
  }
  .fixed-header-logo {
    justify-content: center;
  }
}

@media only screen and (min-width: 1200) {
  .header-search-bar {
    max-width: 850px !important;
  }
}

.credits-popper-shadow {
  background-color: red;
}

//---- cart-wishlist btn styling ----
.icon-btn {
  width: 51px;
  height: 51px;
  background-color: $footprint-primary;
  box-shadow: 0 1px 2px 0 rgba(50, 50, 50, 0.18);
  display: flex !important;
  display: -ms-flexbox !important;
  justify-content: center !important;
  -ms-flex-pack: center !important;
  align-items: center !important;
  -ms-flex-align: center !important;
  padding: 0 !important;
  cursor: pointer;
  i {
    font-size: 1.375rem;
    color: $base;
  }
  &:hover {
    background-color: $footprint-primary;
  }
}

//-----menu icon style----
.menu-icon-wrap {
  background-color: $transparent;
  i {
    color: $base;
  }
}

//----cart and wishlist section styling-----
.iron-cart-list-wrap,
.iron-wish-list-wrap {
  width: 18.75rem;
  display: block !important;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  .cart-menu-item {
    border-bottom: 1px solid $border;
    list-style: none;
    .cart-thumb {
      width: 60px;
      display: flex;
      display: -ms-flexbox;
      justify-content: center;
      -ms-flex-pack: center;
      align-items: center;
      -ms-flex-align: center;
    }
    .cart-content-wrap {
      width: calc(100% - 60px);
      .cart-content {
        padding-left: 0.9375rem;
        width: 55%;
      }
      .cart-edit-action {
        width: calc(100% - 55%);
        .button.icon-btn {
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease-in-out 0s;
          min-width: 2rem !important;
          min-height: 2rem !important;
          width: 2.1rem !important;
          height: 2.1rem !important;
          border-radius: 100% !important;
          padding: 0 !important;
          background-color: $primary;
          i {
            font-size: 1.3rem;
          }
        }
      }
    }
    &:hover {
      .cart-content-wrap {
        .cart-edit-action {
          .button.icon-btn {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
.iron-wish-list-wrap {
  .cart-menu-item {
    .cart-content-wrap {
      width: 80%;
      .cart-content {
        width: 75%;
      }
      .cart-edit-action {
        width: calc(100% - 75%);
      }
    }
  }
}
//----- horizontal-menu style -------
.iron-header-menu {
  li {
    padding: 0 0.625rem;
    height: auto;
    display: inline-block;
    position: relative;
    a {
      line-height: 4rem;
      font-size: 0.82rem;
      font-weight: 500;
      letter-spacing: 0.4px;
      position: relative;
      padding: 0 0.375rem;
      display: inline-block;
      text-transform: uppercase;
      &:after {
        content: '';
        height: 5px;
        background: $active;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transition: transform 0.3s ease-out;
        transform: scaleX(0);
        transform-origin: 50%;
      }
    }
    .sub-menu {
      position: absolute;
      top: 100%;
      opacity: 0;
      visibility: hidden;
      z-index: 99;
      transition: transform 0.3s ease-out 0s;
      transform: translateY(50px);
      width: 14rem;
      background-color: $base;
      box-shadow: $box-shadow;
      li {
        display: block;
        padding: 0;
        a {
          color: #3a405b;
          line-height: 2.8125rem;
          text-align: left;
          display: block;
          &:after {
            display: none;
          }
          &:hover {
            color: #3a405b;
          }
        }
        .sub-menu {
          left: 100%;
          top: 0;
        }
        > .sub-menu-child {
          position: absolute;
          top: 0;
          right: 100%;
          opacity: 0;
          visibility: hidden;
          z-index: 99;
          transition: transform 0.3s ease-out 0s;
          transform: translateY(50px);
          width: 14rem;
          background-color: $base;
          box-shadow: $box-shadow;
        }
      }
    }
    &:first-child {
      padding-left: 0;
    }
    &:hover {
      background-color: $transparent;
      a:after {
        transform: scaleX(1);
      }
      > .sub-menu {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        > li {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
          &:hover {
            > .sub-menu-child {
              transform: translateY(0);
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
  > li {
    &:last-child {
      padding-right: 0;
    }
  }
  .mega-menu {
    position: static;
    > .sub-menu {
      width: 64rem;
      margin: 0 auto;
      left: 0;
      right: 0;
      overflow: hidden;
      padding: 3rem;
      li {
        border: none;
      }
      > li {
        display: inline-block;
        width: 25%;
        > a {
          color: $primary;
          border-bottom: 1px solid #d5d5d5;
          font-size: 1.25rem;
        }
        > .sub-menu {
          position: static;
          transform: none;
          box-shadow: none;
          li {
            a {
              position: relative;
              padding-left: 1.875rem;
              text-transform: capitalize;
              &:before {
                content: '-';
                position: absolute;
                left: 0.9375rem;
              }
            }
          }
        }
      }
    }
  }
}
