/* v1.0.1 Custom CSS - Do Not Modify Classes w/out Notice */
/* Client / Portal Specific Changes Go in Dedicated Area */

/* Client / Portal Specific Changes Go in Dedicated Area */

/* SETUP - Portal Color Variables - START */
:root {
  --mf-primary-color: #012979;
  --mf-secondary-color: #212153;
  --mf-accent-color: #056d82;
  --mf-white: #ffffff;
  --mf-black: #000000;
  --bradius: 4px;
}
/* SETUP - Portal Color Variables - END */

/*.nav-search {
	visibility: hidden;
}
*/

/* CORE MF THEME - START */
/*
.navbar-location {
	margin-top: 0px;
} 
*/
/* MENU - START */
/* Menu Bar Background */
.iron-header-v1 .iron-header-bottom {
  background-color: var(--mf-primary-color) !important;
}

/* Menu Bar Font Color */
.iron-header-v1 .iron-header-menu li a {
  color: var(--mf-white) !important;
}

/* Menu Bar on Hover */
.iron-header-v1 .iron-header-menu li a:hover {
  background-color: var(--mf-secondary-color) !important;
  color: var(--mf-white) !important;
}

/* Menu Bar Bottom Accent Color on Hover */
.iron-header-menu li a:after {
  background: var(--mf-secondary-color) !important;
}

/* Sticky Menu Bar Background */
.iron-fixed-header {
  background-color: var(--mf-primary-color) !important;
}

/* Mobile Menu Background */
.sidebar-area > div:nth-child(2) {
  background-color: var(--mf-primary-color) !important;
}

/* FIX - Mobile Menu Background Coverage */
.jss69 {
  left: 0 !important;
  right: 30% !important;
}
/* MENU - END */

/* FIX - Center Image on Register Page */
.register-image {
  background-position: center !important;
}

/* FIX Hide Page Header Text */
.page-title-bar * {
  color: #fff0;
}

/* Product Card Icon Background Color */
.iron-product-item .iron-btn-grp .btn-wrap {
  background-color: var(--mf-secondary-color) !important;
}

/* My Account > My Orders - Show Details Button */
.btn-active {
  background-color: var(--mf-primary-color);
}

/* My Account > My Orders > Show Details > Card Header Background Color */
.card-header {
  background-color: var(--mf-primary-color) !important;
}

/* Product Page - Add to Cart Modal 'Ok Button' Background Color */
.sweet-alert .btn-warning {
  background-color: var(--mf-primary-color);
}

/* Cart Icon Qty Background */
.badge-active span {
  background-color: var(--mf-accent-color) !important;
}

/* Cart Icon Background */
.icon-btn {
  background-color: var(--mf-primary-color) !important;
  border-radius: var(--bradius);
}

/* Cart Icon Background on Hover */
.icon-btn:hover {
  background-color: var(--mf-accent-color) !important;
}

/* Hyperlinks */
a {
  color: var(--mf-accent-color) !important;
  outline: none !important;
}

a:hover,
a:focus {
  color: var(--mf-secondary-color) !important;
}
/* Hyperlinks END */

/* Contact Page Icon Background */
.contact-icon {
  background: var(--mf-primary-color) !important;
}

/* Product Title on Category Card */
.d-block {
  color: var(--mf-primary-color) !important;
}

/* Pre-Header - Background Color */
.iron-header-v1 .top-links-container {
  background-color: var(--mf-white) !important;
}

/* Header - Background Color */
.iron-header-v1 .iron-header-top {
  background-color: var(--mf-white) !important;
}

/* Cart Icon Dropdown - List Item Icons */
.iron-cart-list-wrap .cart-menu-item .cart-content-wrap .cart-edit-action .button.icon-btn {
  background-color: var(--mf-accent-color) !important;
}

/* My Account > Account Dashboard - View All Link Color */
.iron-dashboard-page-wrap .recent-orders-wrap .recent-orders-wrap-header a {
  color: var(--mf-accent-color) !important;
}

/* My Account > My Address Book - New Address Button */
.add-address-btn {
  background: var(--mf-accent-color) !important;
}

/* Checkout - Payment Text */
.payment-total {
  color: var(--mf-accent-color) !important;
}

/* Checkout Page Buttons - Place Your Order */
.checkout-btn button,
.basket-checkout-btn .btn-active {
  background-color: var(--mf-secondary-color) !important;
}

.checkout-btn > button > span {
  color: var(--mf-white) !important;
}
/* Checkout Page Buttons - Place Your Order - END */

/* Page Content - Feature - Icon Background Color */
.iron-features-v2 .iron-feature-icon span {
  background-color: var(--mf-accent-color) !important;
}

/* Page Content - ProductSlider - Price Color */
.iron-product-item .price-wrap span {
  color: var(--mf-accent-color) !important;
}

/* Page Content - ProductSlider - Category Title Font Color */
.product-slider .product-slider-selected-category {
  color: var(--mf-accent-color) !important;
}

/* Trash Icon on Cart Page */
.iron-cart-wrapper .cart-btn i {
  color: var(--mf-accent-color) !important;
}

/* Product Price Color on Category Card */
.product-info-theme {
  color: var(--mf-primary-color) !important;
}

/* Co-Op Title & Price Color on Product Page */
.active-color {
  color: var(--mf-accent-color) !important;
}

/* Buttons */
.button {
  background-color: var(--mf-primary-color) !important;
}

/* Modal Save Button */
.btn-success {
  background-color: var(--mf-primary-color) !important;
  border-color: var(--mf-secondary-color) !important;
  color: var(--mf-white) !important;
}

.btn-success:hover {
  background-color: var(--mf-primary-color) !important;
  border-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
}

/* Checkout - Add Address Button */
.btn-primary {
  background-color: var(--mf-primary-color);
  border-color: var(--mf-primary-color);
  color: var(--mf-white);
}

.btn-primary:hover {
  background-color: transparent;
  border-color: var(--mf-primary-color);
  color: var(--mf-primary-color);
}
/* Checkout - Add Address Button - END */

/* Modal Close Button */
.btn-danger {
  background-color: var(--mf-accent-color);
  border-color: var(--mf-secondary-color);
  color: var(--mf-white);
}

.btn-danger:hover {
  background-color: var(--mf-accent-color);
  border-color: var(--mf-primary-color);
  color: var(--mf-white);
}
/* Modal Close Button - END */

/* FIX - Qty Box Width on FF */
.increment-quantity {
  width: -moz-fit-content !important;
}

/* Footer Background Color and Padding Adjustment */
.iron-footer-v1 .iron-footer-top {
  background-color: var(--mf-primary-color) !important;
  padding-top: 2.75rem !important;
}

/* FIX - Fat Image Styling */
.iron-cta-item.custom-cta {
  border-radius: 7px !important;
  transition: 0.4s ease-in-out !important;
}

.iron-cta-item.custom-cta:hover {
  box-shadow:
    0 0 5px 0 rgba(0, 0, 0, 0.12),
    0 7px 9px 0 rgba(0, 0, 0, 0.24) !important;
}
/* FIX - Fat Image Styling - END */

/* FIX - Portal Logo Scale */
.iron-app-logo > a > img {
  /* width: 174px !important;
  max-width: max-content !important; */
}

/* Depreciated CSS - Old Side Search / Filters */
.filter-container {
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
  background-color: var(--mf-white) E0 !important;
  border-radius: 6px !important;
}

/* FIX - Category Card Rounded Edges */
.jss21 {
  border-radius: 8px !important;
}

/* Pagination Indicator Color */
.smart-pager .smart-pager-page-index-selector[selected] {
  background-color: var(--mf-secondary-color) !important;
}

/* Filter Accordian Qty Count Colors */
.badge-secondary {
  background-color: var(--mf-accent-color) !important;
  color: var(--mf-white) !important;
  border-radius: var(--bradius);
}

/* Custom Tag Set CSS */
.product-page-red-block {
  height: 50px;
  width: 50px;
  background: red;
  position: absolute;
  right: 0;
}

.product-page-blue-block {
  height: 50px;
  width: 50px;
  background: blue;
  position: absolute;
  left: 0;
}

/* CORE MF THEME - END */

/* PORTAL CSS */
/* Client Specific CSS Adjustments Go Here */

.page-title-bar {
  padding: 0rem 0 !important;
}

.app-container > div:nth-child(2) {
  background: #f5f5f5;
}

/* Client Specific CSS Adjustments - END */
/* PORTAL CSS - END */

/* v1.0.1 Custom CSS - Do Not Modify Classes w/out Notice */
/* Client / Portal Specific Changes Go in Dedicated Area */
