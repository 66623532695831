/*------------------------------------------------------------
4.5 text
-----------------------------------------------------------*/

.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
//.text-truncate { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left {
      text-align: left !important;
    }
    .text#{$infix}-right {
      text-align: right !important;
    }
    .text#{$infix}-center {
      text-align: center !important;
    }
  }
}

// Transformation

.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}

.font-bold {
  font-weight: $font-weight-bold;
}
.font-italic {
  font-style: $font-weight-italic;
}
.font-normal {
  font-weight: $font-weight-base;
}
.font-medium {
  font-weight: $font-weight-medium;
}
.font-light {
  font-weight: 300;
}
.text-14 {
  font-size: 0.875rem;
}
.text-underline {
  text-decoration: underline;
}
